
import { Watch, Component, Vue, toNative, mixins } from 'vue-facing-decorator'
import { ListViewVue } from '@/common/BaseVue';
@Component({
  components: {}
})
export default class extends mixins(ListViewVue) {
  listType = "router";

  tablist = [
    {
      name: "会员活动",
      value: "",
    },
  ]
  activeName = ""

  async deleteRow(id) {
    let { error, message } = await this.$post("/manager/activity/delete", { id });
    if (error) return this.$error(message);
    this.$success("删除成功");
    this.refresh();
  }
  getList() {
    return this.$get("/manager/activity/list", { ...this.mesureData });
  }
  createActive(id?) {
    if (id) {
      return this.$goto({
        name: "activity-operat",
        query: {
          id,
        },
      });
    }
    this.$goto({
      name: "activity-operat",
      query: {},
    });
  }
}
