import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-41ed54dd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "operat-button-box" }
const _hoisted_2 = { class: "userinfo-box" }
const _hoisted_3 = { style: {"color":"red"} }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ui_button = _resolveComponent("ui-button")!
  const _component_ui_switch = _resolveComponent("ui-switch")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_ui_table = _resolveComponent("ui-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_ui_button, { onClick: _ctx.editInfo }, {
        default: _withCtx(() => [
          _createTextVNode("编辑会员")
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_ui_button, { onClick: _ctx.taskInfo }, {
        default: _withCtx(() => [
          _createTextVNode("积分任务")
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_ui_switch, {
        style: {"margin-left":"10px"},
        "false-value": 0,
        modelValue: _ctx.store.vipSwitch,
        "onUpdate:modelValue": _ctx.changeVip,
        "true-value": 1
      }, {
        default: _withCtx(() => [
          _createTextVNode(" 开启积分等级 ")
        ]),
        _: 1
      }, 8, ["modelValue", "onUpdate:modelValue"])
    ]),
    _createVNode(_component_ui_table, { data: _ctx.list }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          label: "会员级别",
          width: "130"
        }, {
          default: _withCtx(({ row, $index }) => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("span", _hoisted_3, "VIP" + _toDisplayString($index + 1), 1)
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, { label: "名称" }, {
          default: _withCtx(({ row }) => [
            _createElementVNode("div", null, _toDisplayString(row.name || "-"), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: "获得条件",
          prop: "invitor"
        }, {
          default: _withCtx(({ row }) => [
            (row.id)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(row.score) + "积分", 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_5, "-"))
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, { label: "会员人数" }, {
          default: _withCtx(({ row }) => [
            _createElementVNode("span", null, _toDisplayString(row.count || "-"), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["data"])
  ]))
}